#root {
  background-color: #13101C !important;

}
.table{
  --cui-table-bg: none;
  
}
.table thead{
  font-weight: 600;
}

/***********Tables View Styling****************/

.cardView {
  background: black;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}


.cardView h1{
  color: white;
  border-bottom: 2px solid black;
}
.cardView h6{
  font-weight: 700;
    font-size: 18px;
    padding-top: 15px;
    color: white;
}
.cardView span{
  color: darkgrey;
    font-size: 14px;
}
.dropdown-item {
  background-color: black;
}
.dropdown-item:hover {
  background-color: lightgray;
  color: black !important;
}


/**********Spinner********/

.center-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background: linear-gradient(90deg, #d2dbf0ce, #ac83e4, #eaa7d1, #fece84, #e28ca3),
}

.footer {
  --cui-footer-bg: none;
  --cui-footer-border-color: var(--cui-border-color);
  --cui-footer-border: 2px solid white;
  color: white;
}
